import * as firebase from "firebase/app"
import "firebase/firestore"
import "firebase/analytics"

const config = {
  apiKey: "AIzaSyDBplSM6bIWO9SO7mgPDQapJiVjz5xb70Y",
  authDomain: "keach-carpentry.firebaseapp.com",
  databaseURL: "https://keach-carpentry.firebaseio.com",
  projectId: "keach-carpentry",
  storageBucket: "keach-carpentry.appspot.com",
  messagingSenderId: "724302078913",
  appId: "1:724302078913:web:25814095b3e9243e0f8540",
  measurementId: "G-06BNW39R52",
}
// Initialize Firebase
firebase.initializeApp(config)

export default firebase
