import React from "react"
import firebase from "../Firebase/firebase"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Icon } from "react-icons-kit"
import { envelope } from "react-icons-kit/fa/envelope"
import { phone } from "react-icons-kit/fa/phone"

import "./ContactSection.css"

import ContactSectionForm from "../ContactForms/ContactSectionForm"

function ContactSection() {
  const handleClick = type => {
    firebase.analytics().logEvent(type)
  }

  return (
    <section id="small-contact-section">
      <Container>
        <Row className="justify-content-center">
          <Col md={10} lg={8} className="d-flex align-items-stretch">
            <div className="contact-form-wrapper">
              <p className="display-5">Speak with an expert</p>
              <p className="display-4">Request contact</p>
              <ContactSectionForm />
            </div>
          </Col>

          <Col md={10} lg={4} className="d-flex align-items-stretch">
            <div className="contact-info-wrapper">
              <p className="display-5">How can we help?</p>
              <p className="display-4">Contact Us</p>
              <ul className="list-unstyled pt-4">
                <li>
                  <Icon size={20} icon={phone} />
                  <a
                    className="text-normal"
                    href="tel:1-508-230-9528"
                    onClick={() => {
                      handleClick("Call 508-230-9528")
                    }}
                  >
                    <span>(508) 230-9528</span>
                  </a>
                </li>
                <li>
                  <Icon size={20} icon={phone} />
                  <a
                    className="text-normal"
                    href="tel:1-508-942-0587"
                    onClick={() => {
                      handleClick("Call 508-942-0857")
                    }}
                  >
                    <span>(508) 942-0587</span>
                  </a>
                </li>
                <li>
                  <Icon size={20} icon={envelope} />
                  <a
                    className="text-normal"
                    href="mailto:keachcarpentry@yahoo.com"
                    onClick={() => handleClick("Email link")}
                  >
                    <span> keachcarpentry@yahoo.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactSection
