import React from "react"

import Col from "react-bootstrap/Col"

const SectionTitle = props => (
  <Col xs={12}>
    <h2 className="section-title">{props.children}</h2>
  </Col>
)

export default SectionTitle
