export default function validateAuth(values) {
  let errors = {}
  // Email Errors
  if (!values.name) {
    errors.name = "Required Name"
  } else if (values.name.length < 1) {
    errors.name = "Required Name"
  }
  if (!values.email) {
    errors.email = "Required Email"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }

  if (!values.phone) {
    errors.phone = "Required Phone Number"
  } else if (values.phone.length < 9) {
    errors.phone = "Invalid phone number"
  }

  if (!values.message) {
    errors.message = "Required Message"
  } else if (values.message.length < 1) {
    errors.message = "Required Message"
  }

  return errors
}
