import React, { useState, useEffect } from "react"
import sendEmail from "../sendEmails/sendEmail"

function useFormValidation(initialState, validate) {
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0
      if (noErrors) {
        sendEmail(values)
        console.log(
          "authenticated!",
          values.name,
          values.email,
          values.phone,
          values.message
        )
        setSubmitting(false)
        setSubmitted(true)
      } else {
        setSubmitting(false)
        console.log(errors)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  function handleChange(event) {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  function handleBlur() {
    const validationErrors = validate(values)
    setErrors(validationErrors)
  }

  function handleSubmit(event) {
    event.preventDefault()
    const validationErrors = validate(values)
    setErrors(validationErrors)
    setSubmitting(true)
  }

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    isSubmitted,
  }
}

export default useFormValidation
