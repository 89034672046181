import React from "react"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import Img from "gatsby-image"

import CtaButton from "../LayoutComp/CtaButton"
import SectionTitle from "../LayoutComp/SectionTitle"

const PortfolioSection = props => {
  return (
    <section>
      <Container>
        <SectionTitle>Featured Projects</SectionTitle>
        <Row>
          {props.gallery[0] && (
            <Col md={6} lg={8}>
              <Img fluid={props.gallery[0]} alt="Portfolio image" />
            </Col>
          )}
          {props.gallery[1] && (
            <Col md={6} lg={4}>
              <Img fluid={props.gallery[1]} alt="Portfolio image" />
              <Img
                className="d-lg-block d-none"
                fluid={props.gallery[2]}
                alt="Portfolio image"
              />
            </Col>
          )}
          {props.gallery[2] && (
            <Col md={6} lg={4} className="d-lg-none">
              <Img fluid={props.gallery[2]} alt="Portfolio image" />
            </Col>
          )}
          {props.gallery[3] && (
            <Col md={6} lg={4}>
              <Img fluid={props.gallery[3]} alt="Portfolio image" />
            </Col>
          )}
          {props.gallery[4] && (
            <Col md={6} lg={4}>
              <Img fluid={props.gallery[4]} alt="Portfolio image" />
            </Col>
          )}
          {props.gallery[5] && (
            <Col md={6} lg={4}>
              <Img fluid={props.gallery[5]} alt="Portfolio image" />
            </Col>
          )}
          {props.gallery[6] && (
            <Col md={6} lg={4}>
              <Img fluid={props.gallery[6]} alt="Portfolio image" />
            </Col>
          )}
          {props.gallery[7] && (
            <Col md={6} lg={4}>
              <Img fluid={props.gallery[7]} alt="Portfolio image" />
            </Col>
          )}
          {props.gallery[8] && (
            <Col md={6} lg={4}>
              <Img fluid={props.gallery[8]} alt="Portfolio image" />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} className="text-center mb-5">
            <CtaButton to="/projects" type="link small">
              View more projects
            </CtaButton>
          </Col>
        </Row>
        {props.children}
      </Container>
    </section>
  )
}

export default PortfolioSection
