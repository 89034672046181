import React from "react"
import firebase from "../../Firebase/firebase"

export default function sendEmail({ name, email, phone, message }) {
  const db = firebase.firestore()
  const messagesRef = db.collection("messages")

  messagesRef.add({
    name: name,
    email: email,
    phone: phone,
    message: message,
  })
}
