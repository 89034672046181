import PropTypes from "prop-types"
import React from "react"

import "./Header.css"

import NavBarHeader from "./navigation"

const Header = ({ siteTitle, mobileNavToggler }) => (
  <>
    <header>
      <a href="#main" tabIndex="0" className="skip-nav">
        Skip to main content
      </a>
      <NavBarHeader mobileNavToggler={mobileNavToggler} />
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
