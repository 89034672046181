import React from "react"
import { Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

const NavBarFooter = () => (
  <Navbar variant="dark" expand="md" id="footer-nav">
    <Container fluid="true">
      <Navbar.Collapse>
        <Nav as="ul">
          <Nav.Item as="li">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link" to="/about-us">
              About Us
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link" to="/services">
              Services
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link" to="/projects">
              Projects
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

export default NavBarFooter
