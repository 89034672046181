import React from "react"
import { Link } from "gatsby"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import "./MobileNavbar.css"

const MobileNavbar = props => {
  return (
    <Navbar
      variant="dark"
      className={props.show && "navigation-open"}
      id="mobile-nav"
    >
      <Nav as="ul" className="flex-column align-items-center">
        <Nav.Item>
          <Link to="/" activeClassName="active" className="nav-link">
            Home
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/about-us" activeClassName="active" className="nav-link">
            About Us
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/services" activeClassName="active" className="nav-link">
            Services
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/projects" activeClassName="active" className="nav-link">
            Projects
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/contact" activeClassName="active" className="nav-link">
            Contact
          </Link>
        </Nav.Item>
      </Nav>
    </Navbar>
  )
}

export default MobileNavbar
