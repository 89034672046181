import React from "react"
import { Link } from "gatsby"

const CtaButton = props => (
  <Link
    to={props.to ? props.to : "/contact"}
    className={`btn btn-${props.type ? props.type : "danger"} btn-mod`}
  >
    {props.children}
  </Link>
)

export default CtaButton
