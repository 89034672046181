import React from "react"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import useFormValidation from "./validation/useFormValidation"
import validateAuth from "./validation/validateAuth"

const INITIAL_STATE = {
  email: "",
  name: "",
  message: "",
  phone: "",
}

function ContactSectionForm() {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
    isSubmitted,
  } = useFormValidation(INITIAL_STATE, validateAuth)

  if (!isSubmitted) {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Row className="justify-content-center justify-content-lg-start">
          <Col xs={11} md={8} lg={4}>
            <Form.Control
              onChange={handleChange}
              name="name"
              value={values.name}
              type="text"
              className={`form-control-mod ${errors.name && "error-input"}`}
              id="inlineFormInputName"
              placeholder="Full name"
              aria-label="Your full name"
              aria-required="true"
            />
          </Col>
          <Col xs={11} md={8} lg={4}>
            <Form.Control
              onChange={handleChange}
              name="phone"
              type="text"
              className={`form-control-mod ${errors.phone && "error-input"}`}
              id="inlineFormInputPhone"
              placeholder="Phone number"
              aria-label="Your email"
              aria-required="true"
            />
          </Col>
          <Col xs={11} md={8} lg={4}>
            <Form.Control
              onChange={handleChange}
              name="email"
              value={values.email}
              type="email"
              className={`form-control-mod ${errors.email && "error-input"}`}
              id="inlineFormInputEmail"
              placeholder="Email Address"
              aria-label="Your phone number"
              aria-required="true"
            />
          </Col>
          <Col xs={11} md={8} lg={8}>
            <Form.Control
              onChange={handleChange}
              name="message"
              value={values.message}
              as="textarea"
              rows="2"
              className={`form-control-mod ${errors.message && "error-input"}`}
              id="inlineFormInputMessage"
              placeholder="Message"
              aria-label="Message"
              aria-required="true"
            />
          </Col>

          <Col xs={6} md={5} lg={4} className="align-self-end">
            <Button
              type="submit"
              disabled={isSubmitting}
              variant={"outline-dark"}
              block
            >
              Request contact
            </Button>
          </Col>
        </Form.Row>
      </Form>
    )
  } else {
    return (
      <Form>
        <Form.Row className="justify-content-center justify-content-lg-start">
          <Col xs={12} className="pb-2">
            <p className="h4 pb-2">Thank you for contacting us.</p>
            <p>
              We will look over your message and get back to you within 24h.
            </p>
          </Col>
        </Form.Row>
      </Form>
    )
  }
}

export default ContactSectionForm
