import React from "react"
import "./Backdrop.css"

const Backdrop = props => (
  <div
    id="backdrop"
    className={props.show && "navigation-open"}
    onClick={props.click}
    onKeyDown={props.click}
    role="presentation"
  ></div>
)

export default Backdrop
