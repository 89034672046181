import React from "react"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import CtaButton from "../LayoutComp/CtaButton"

import "./CtaSection.css"

function CtaSection(props) {
  return (
    <section className="cta-section">
      <Container>
        <Row>
          <Col xs={12}>
            <h2>
              {props.children ? (
                props.children
              ) : (
                <span>
                  Lorem ipsum dolor sit amet consectetur <br />
                  adipisicing elit.
                </span>
              )}
            </h2>
            <CtaButton>
              {props.cta ? props.cta : "Start Your Project"}
            </CtaButton>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default CtaSection
