import React from "react"

import Nav from "react-bootstrap/Nav"

import { Icon } from "react-icons-kit"
import { bars } from "react-icons-kit/fa/bars"

import "./MobileNavButton.css"

const MobileNavToggle = props => {
  return (
    <Nav as="ul" id="mobile-nav-toggle-wrapp">
      <Nav.Item
        as="button"
        className="btn btn-link text-light"
        id="mobile-nav-button"
        onClick={props.click}
      >
        <Icon size={20} icon={bars} />
      </Nav.Item>
    </Nav>
  )
}

export default MobileNavToggle
