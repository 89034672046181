import React from "react"
import { Link } from "gatsby"

import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import logo from "../../images/logo.png"
import MobileNavToggle from "../MobileNav/MobileNavButton"

const NavBarHeader = props => {
  return (
    <Navbar variant="dark" collapseOnSelect expand="lg" id="main-nav">
      <Container fluid="true">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="" width="100" />
        </Link>
        <Navbar.Collapse>
          <Nav as="ul" className="ml-auto pr-5">
            <Nav.Item as="li">
              <Link className="nav-link" activeClassName="active" to="/">
                Home
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                className="nav-link"
                activeClassName="active"
                to="/about-us"
              >
                About Us
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                className="nav-link"
                activeClassName="active"
                to="/services"
              >
                Services
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                className="nav-link"
                activeClassName="active"
                to="/projects"
              >
                Projects
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link className="nav-link" activeClassName="active" to="/contact">
                Contact
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <MobileNavToggle click={props.mobileNavToggler} />
      </Container>
    </Navbar>
  )
}

export default NavBarHeader
