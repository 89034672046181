/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./Style.css"
import "./Queries.css"

import Header from "./Header"
import Footer from "./Footer"
import MobileNavbar from "./MobileNav/MobileNavbar"
import Backdrop from "./Backdrop"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const toggleMobileNavOpen = () => {
    setMobileNavOpen(!mobileNavOpen)
  }

  const backdropCloseMobileNav = () => {
    setMobileNavOpen(false)
  }

  let mobileNavBar
  let backdrop

  if (mobileNavOpen) {
    mobileNavBar = <MobileNavbar />
    backdrop = <Backdrop click={backdropCloseMobileNav} show={mobileNavOpen} />
  }

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        mobileNavToggler={toggleMobileNavOpen}
      />
      <MobileNavbar show={mobileNavOpen} />
      {backdrop}

      <main className={mobileNavBar && "navigation-open"} id="wrapper">
        {children}
      </main>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
