import React from "react"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { Icon } from "react-icons-kit"
import { facebookSquare } from "react-icons-kit/fa/facebookSquare"
import { instagram } from "react-icons-kit/fa/instagram"

import "./Footer.css"
import NavBarFooter from "./navigation"
import CtaButton from "../LayoutComp/CtaButton"

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-center mt-3">
          <Col xs={12} lg>
            <h2>Ready to discuss your next project?</h2>
          </Col>
          <Col xs="auto">
            <CtaButton>Start Your Project</CtaButton>
          </Col>
          <div className="w-100 mb-4"></div>

          <Col xs={12} className="mb-4 text-center">
            <p>
              &copy; Bob Keach &amp; Sons Carpentry 2020 | 86 Chestnut St
              Easton, Massachusetts 02356 AS
            </p>
            <p>
              <a href="mailto:keachcarpentry@yahoo.com">
                keachcarpentry@yahoo.com
              </a>
              | <a href="tel:1-508-230-9528">(508) 230-9528</a>|{" "}
              <a href="tel:1-508-942-0587">(508) 942-0587</a>
            </p>
            <p className="social-icons">
              <a
                className="facebook-icon"
                href="https://www.facebook.com/bobkeachandsonscarpenty/"
              >
                <Icon size={22} icon={facebookSquare} />
              </a>
              <a
                className="instagram-icon"
                href="https://www.instagram.com/bobkeachandsonscarpentry/"
              >
                <Icon size={22} icon={instagram} />
              </a>
            </p>
          </Col>
          <Col xs={12}>
            <hr className="border-secondary" />
          </Col>
          <Col xs={12}>
            <NavBarFooter />
          </Col>
        </Row>
      </Container>
      <div className="copyright">
        <Container className="container">
          <Row>
            <Col>
              <p>&copy; Bob Keach &amp; Sons Carpentry</p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
